<template>
  <base-layout  >
    <ion-content class="has-header has-subheader "   >

<div >
  <div style="width:100%; padding: 15px"> <span style="font-size:24px; font-weight: 500;" > {{ wordings[currentLanguage].SPECIAL_THANKS }} </span> </div>
  <ion-list>
  <div  v-for="sponsor in filteredSponsors" :key="sponsor.id" >
    
    <ion-item lines="full" ion-no-padding style="min-height:120px; padding:0px !important" class="ion-no-margin" v-if="sponsor.id" detail >
      
       <div  @click="showDisclaimer(sponsor)" style="margin-bottom:5px; width:100%; text-align: center;">
          <img
              v-if="sponsor.logo"
              :src="'https://fileserver-188ab.kxcdn.com/file/' +sponsor.logo.split('/')[4]"
              style="max-height: 60px; padding-top:15px"
          />
       
           <div v-else class="mt-3 p-2"> {{sponsor.name}}</div>

          <div style="margin-top:10px;" v-if="sponsor.locationList.length>0"> {{ wordings[currentLanguage].BOOTHS }}  <br>
          <small v-for="location in sponsor.locationList" :key="location.id"> {{ location }} <br> </small>
          </div>
           <div  v-else style="margin-top:10px; margin-bottom:10px">
           <small> {{ wordings[currentLanguage].NO_BOOTHS }} <br> </small>
          </div>
        </div>
    </ion-item >
     <div
          class="web-person-divider"
          v-if="!sponsor.id && sponsor != ' ' "
          style="border-top: 0.1px solid #CBCBCB; flex: 0 0 100vw;width: 100vw;padding-left:20px" 
      >
        <span style="font-size:18px; font-weight:500">{{ sponsor }}</span>
      </div>

         <div
          class="web-person-divider"
          v-if="!sponsor.id && sponsor == ' ' "
          style="border-top: 0.1px solid #CBCBCB; flex: 0 0 100vw;width: 100vw;padding-left:20px" 
      >
       <span style="color: #F8F8F8 !important; "> No Sponsor Level </span>
      </div>
  </div>

      </ion-list>
</div>

    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, alertController} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";

export default defineComponent({
  name: "Exhibitors",
  data() {
    return {
      sponsors: [],
      search: "",
      filtered: []
    };
  },
  components: {
    IonContent,
  },
  methods: {
    ...mapActions("exhibitors", ["getSponsorList"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sponsors.filter(sponsor =>
            Object.keys(sponsor).some(k =>
                sponsor[k]
                    ? sponsor[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
      } else {
        this.filtered = [];
      }
    },

    async showDisclaimer (sponsor) {
      if(sponsor.disclaimer && sponsor.disclaimer.length > 10){
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: sponsor.name,
              message: '<strong>' + sponsor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(sponsor.name);
                    if (sponsor.disclaimer_link) {
                      window.open(sponsor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + sponsor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      }else if(sponsor.disclaimer_link && sponsor.disclaimer_link.length > 10){
        window.open(sponsor.disclaimer_link, '_blank', 'location=no');
      }else{
        this.$router.push('/app/exhibitors/' + sponsor.id);
      }

    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('wordings',['wordings', 'currentLanguage']),

    filteredSponsors: function () {
      return this.filtered.length ? this.filtered : this.sponsors;
    }
  },
  async created() {
    this.sponsors = await this.getSponsorList();
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.sponsors = await this.getSponsorList();
      }
    },
  },
});
</script>
<style lang="scss">
a {
  text-decoration: none;
}


</style>
